import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    formControlClass: {
      width: '100%',
      top: '-4px',
      marginLeft: '10px',
      '& > div': {
        backgroundColor: '#fafafa',
        '& img': {
          width: '18px',
        },
      },
      '& .admin-portal-availability-mfe-MuiSelect-select.admin-portal-availability-mfe-MuiSelect-select':
        {
          padding: '6px 32px 6px 20px',
          fontSize: '14px',
        },
    },
    selectClass: {
      borderRadius: '20px',
      transition: '0.3s',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: 'rgba(204, 204, 204, 0.3)',
      },
    },
    iconOption: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      '& img': {
        width: '18px !important',
      },
    },
  })
);
