import PaginationInfo from "domains/PaginationInfo";

export const DEFAULT_PAGE_SIZE = 20;
export const DEFAULT_FIRST_PAGE = 1;
export const ROUTER_BASENAME = '/availability';
export const OPTIMIZELY_PROJECT_NAME = 'BEES LOAT - Inventory Visibility';

export const CLIENT_JOB_IMPORT_HOUR = '00:00';

export const CLIENT_MAX_FILE_SIZE_MB = 5;
export const SKU_IMPORT_PLUS_MINUTES_REQUEST = 2;

export const CLIENT_MAX_SELECTED_SKU = 5;

export const LOCAL_STORAGE_LANGUAGE_KEY = 'language';
export const LOCAL_STORAGE_COUNTRY = 'selectedCountry';
export const LOCAL_STORAGE_VENDOR = 'selectedVendor';
export const LOCAL_STORAGE_VENDOR_NAME = 'selectedVendorName';
export const LOCAL_STORAGE_USER_EMAIL = 'userEmail';
export const LOCAL_STORAGE_PERMISSION_GROUPS = 'permissionGroups';

export const LIMITED_TYPE_DC = 'DC';
export const LIMITED_TYPE_POC = 'POC';

export const INVENTORY_LEVEL = {
  regular: 'REGULAR',
  critical: 'CRITICAL',
};

export const EXPORT_LIMIT_STATUS = {
  in_progress: 'IN_PROGRESS',
  finished: 'FINISHED',
  failed: 'FAILED',
};
export const IMPORT_LIMIT_STATUS = {
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCESSFULLY_UPLOADED: 'SUCCESSFULLY_UPLOADED',
  UPLOADED_WITH_ERROR: 'UPLOADED_WITH_ERROR',
  ERROR: 'ERROR',
};
export const LIMITS_STATUS = {
  PENDING: 'PENDING',
  SUCCESS: 'SENT',
  LIVE: 'LIVE',
};

export const RULES_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  APPLIED: 'APPLIED',
};

export const RULES_TYPE = {
  DDC: 'DC',
  POC: 'POC',
};

export const DEFAULT_PAGINATION = {
  page: 0,
  size: 100,
};

export const ATP_EXPORT_TYPES = {
  INVENTORY: 'INVENTORY',
  ORDER: 'ORDER'
}


export const DEFAULT_PAGINATION_PAGESIZE: PaginationInfo = {
  page: 0,
  pageSize: 10,
  totalElements: 0,
  totalPages: 0,
};

export const VENDOR_SETTINGS = {
  stockTypeDaily: 'DAILY',
  stockTypeSingle: 'SINGLE'
}