import { useLocation } from 'react-router-dom';
import permissionRoutes from '../../config/permissionRoutes';

export function useRouteScopes(): Array<string> {
  const pathName = useLocation().pathname;

  let permission = permissionRoutes[pathName];
  if (!permission) {
    const lastPathName = pathName.substring(0, pathName.lastIndexOf('/'));
    permission = permissionRoutes[lastPathName];
  }

  return permission;
}
