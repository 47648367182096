import { createStyles, makeStyles } from '@material-ui/core';

export default makeStyles((theme) =>
  createStyles({
    title: {
      fontFamily: 'Work Sans',
      textAlign: 'center',
      fontSize: '26px',
      paddingTop: '14px',
      color: '#000',
      fontWeight: 500,
      '&.empty': {
        fontWeight: 800,
      },
    },
    content: {
      width: '600px',
    },
    text: {
      fontFamily: 'Work Sans',
      fontSize: '16px',
      color: '#000',
      '&.empty': {
        textAlign: 'center',
        paddingBottom: '30px',
      },
    },
    label: {
      fontFamily: 'Work Sans',
      fontSize: '16px',
      color: '#000',
      marginTop: '18px',
    },
    selectComponent: {
      marginTop: '6px',
      marginLeft: 0,
      paddingBottom: '20px',
    },
  })
);
