import * as dateFnsLocaleValues from 'date-fns/locale';
import { createIntl, createIntlCache, defineMessages, MessageDescriptor } from 'react-intl';
import en from '../i18n/languages/en';
import es from '../i18n/languages/es';
import pt from '../i18n/languages/pt';
import { mergeMessages } from './utils';

type fnsLocale = "pt" | "en" | "es";

const locale = 'en-US';
const messages: Record<string, any> = {
  'en-US': en,
  'pt-BR': pt,
  'es-419': es,
};

const intlProvider = (locale: string) => {
  const cache = createIntlCache();
  return createIntl(
    {
      locale,
      messages: defineMessages(mergeMessages(messages, locale, locale)),
    },
    cache
  );
};

let provider = intlProvider(locale);

const formatMessage = (message: MessageDescriptor, value?: Record<string, string>): string =>
  provider.formatMessage(message, value);

const updateIntlProvider = (language?: string | null): void => {
  provider = intlProvider(language || locale);
};

const formatDate = (date: number | Date): string => provider.formatDate(date, { timeZone: 'UTC' });

const dateFnsLocale = (): Locale => {
  const locales: Record<string, Locale> = {
    'en-US': dateFnsLocaleValues.enUS,
    'pt-BR': dateFnsLocaleValues.ptBR,
    'es-419': dateFnsLocaleValues.es
  };

  return locales[provider.locale] ?? pt;
};

const dateFnsLanguage = (): fnsLocale => {
  const locales: Record<string, fnsLocale> = {
    'en-US': "en",
    'pt-BR': 'pt',
    'es-419': 'es'
  };

  return locales[provider.locale] ?? 'en';
};

export default { updateIntlProvider, formatMessage, provider, formatDate, dateFnsLocale, dateFnsLanguage };
