import * as _ from 'lodash';
import CountryList from '../../config/countrys';

interface OptionsItem {
  icon?: string;
  label: string;
  value: string;
}

export const GetUserCountryList = (
  supportedCountryMetadata: any,
  countryCompleteName?: boolean
) => {
  let options: Array<OptionsItem> = [];

  if (supportedCountryMetadata) {
    supportedCountryMetadata.forEach((country: string) => {
      const countryData = CountryList.find((item) => item.code === country);

      if (countryData) {
        options.push({
          label: countryCompleteName ? countryData.name : countryData.code,
          icon: countryData.flag,
          value: countryData.code,
        });
      }
    });
  }

  options = _.sortBy(options, ['label']);

  return options || [];
};

export const CheckLocalStorageSelectedCountry = (
  supportedCountryMetadata: any,
  selectedCountry: string | null
) => {
  const userCountryList = GetUserCountryList(supportedCountryMetadata);

  const existSelected = userCountryList.find((country) => country.value === selectedCountry);

  return existSelected ? true : false;
};
