export const LastURLLocalStorage = 'LastURL';

export enum ScreenName {
    SKUManagement = 'LIMITS_MANAGEMENT',
    LimitsUpload = 'LIMITS_UPLOAD',
    ConsultActiveLimits = 'CONSULT_ACTIVE_LIMITS',
    POCExceptionManagement = 'POC_EXCEPTION_MANAGEMENT',
    ATPSummary = 'ATP_SUMMARY',
    ATPParameters = 'ATP_PARAMETERS',
    FileMonitoring = 'FILE_MONITORING'
}

export enum LimitsUploadTabs {
    Upload = 'UPLOAD',
    History = 'HISTORY'
}

export enum SKUManagementTabs {
    DDC = 'DDC',
    POC = 'POC',
    DOWNLOADS = 'DOWNLOADS'
}

export const ATP_MODULE_TYPE = 'ATP';
export const LIMITS_MODULE_TYPE = 'LIMITS';

export const APP_NAME = 'Inventory';
export const APP_ID = 'AVAILABILITY';

export const HierarchyGroupsLimits = [
    'LOATInventoryVisibility.BEES Admin',
    'LOATInventoryVisibility.SKU limit manager',
    'LOATInventoryVisibility.SKU limit DC manager',
    'LOATInventoryVisibility.SKU limits viewer'
]

export const HierarchyGroupsATP = [
    'LOATInventoryVisibility.BEES Admin',
    'LOATInventoryVisibility.ATP manager',
    'LOATInventoryVisibility.ATP viewer'
]

export const PagesIDs = {
    LIMITS_UPLOAD: 'limits_upload',
    SKU_MANAGEMENT: 'sku_management_dc',
    CONSULT_ACTIVE_LIMITS: 'consult_active_limits',
    POC_EXCEPTION_MANAGEMENT: 'poc_exception_management',
    INVENTORY_SUMMARY: 'inventory_summary',
    FILE_MONITORING: 'file_monitoring',
    STOCK_RESERVATION_PARAMETERS: 'stock_reservation_parameters',
    STOCK_RESERVATION_PARAMETERS_FORM: 'stock_reservation_parameters_form',
}

export const PageRouteData = {
    [PagesIDs.LIMITS_UPLOAD]: {
        page_label: 'Limits Upload',
        page_name: 'LIMITS_UPLOAD',
        page_url: '/availability',
        type: LIMITS_MODULE_TYPE
    },
    [PagesIDs.SKU_MANAGEMENT]: {
        page_label: 'Limited SKU Management',
        page_name: 'LIMITED_SKU_MANAGEMENT',
        page_url: '/availability/summary',
        type: LIMITS_MODULE_TYPE
    },
    [PagesIDs.CONSULT_ACTIVE_LIMITS]: {
        page_label: 'Consult Active Limits',
        page_name: 'LIMITS_PER_CLIENT',
        page_url: '/availability/limits-per-client',
        type: LIMITS_MODULE_TYPE
    },
    [PagesIDs.POC_EXCEPTION_MANAGEMENT]: {
        page_label: 'POC Exception Management',
        page_name: 'POC_EXCEPTION_MANAGEMENT',
        page_url: '/availability/client-management',
        type: LIMITS_MODULE_TYPE
    },
    [PagesIDs.INVENTORY_SUMMARY]: {
        page_label: 'Inventory Summary',
        page_name: 'INVENTORY_SUMMARY',
        page_url: '/availability/stock-balance',
        type: ATP_MODULE_TYPE
    },
    [PagesIDs.STOCK_RESERVATION_PARAMETERS]: {
        page_label: 'Stock Reservation Parameters',
        page_name: 'STOCK_PARAMETERS',
        page_url: '/availability/atp-parameters',
        type: ATP_MODULE_TYPE
    },
    [PagesIDs.STOCK_RESERVATION_PARAMETERS_FORM]: {
        page_label: 'Stock Reservation Parameter Edit',
        page_name: 'STOCK_PARAMETERS_EDIT',
        page_url: '/availability/atp-parameters/form',
        type: ATP_MODULE_TYPE
    },
    [PagesIDs.FILE_MONITORING]: {
        page_label: 'File Monitoring',
        page_name: 'FILE_MONITORING',
        page_url: '/availability/file-monitoring',
        type: ATP_MODULE_TYPE
    }
}