import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import useStyles from './LoadingView.styles';

interface Props {
  show: boolean;
  message?: string;
}

function LoadingView({ show, message }: Props): JSX.Element {
  const { loading, circular, messageBox, messageText } = useStyles();

  return (
    <>
      {show && (
        <div className={loading} data-testid="loading-container">
          {!message && <CircularProgress disableShrink className={circular} />}
          {message && (
            <div className={messageBox}>
              <CircularProgress disableShrink />
              <span className={messageText}>{message}</span>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default LoadingView;
