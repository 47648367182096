import { useHasPermission, usePermissionService } from 'admin-portal-shared-services';

export function CheckOnePermission(userScope: Array<string> | undefined): boolean {
  if (!userScope) return false;
  const hasAccess: Array<boolean> = userScope.map((scope) => {
    return usePermissionService().has(scope);
  });

  return hasAccess.includes(true);
}

export function CheckPermission(userScope: Array<string> | string | undefined): boolean {
  if (!userScope) return false;
  return useHasPermission(userScope);
}
