import AQFlag from '../assets/flags/aq.svg';
import ARFlag from '../assets/flags/ar.svg';
import BEFlag from '../assets/flags/be.svg';
import BOFlag from '../assets/flags/bo.svg';
import BRFlag from '../assets/flags/br.svg';
import COFlag from '../assets/flags/co.svg';
import DEFlag from '../assets/flags/de.svg';
import DOFlag from '../assets/flags/do.svg';
import ECFlag from '../assets/flags/ec.svg';
import ESFlag from '../assets/flags/es.svg';
import GBFlag from '../assets/flags/gb.svg';
import HNFlag from '../assets/flags/hn.svg';
import IDFlag from '../assets/flags/id.svg';
import MXFlag from '../assets/flags/mx.svg';
import NLFlag from '../assets/flags/nl.svg';
import PAFlag from '../assets/flags/pa.svg';
import PEFlag from '../assets/flags/pe.svg';
import PHFlag from '../assets/flags/ph.svg';
import PYFlag from '../assets/flags/py.svg';
import SVFlag from '../assets/flags/sv.svg';
import UYFlag from '../assets/flags/uy.svg';
import ZAFlag from '../assets/flags/za.svg';
import { ICountry } from '../domains/Country';
import intlFormatter from '../i18n/formatter';

export default [
  {
    name: intlFormatter.formatMessage({ id: 'Countries.AR' }),
    code: 'AR',
    flag: ARFlag,
  },
  {
    name: intlFormatter.formatMessage({ id: 'Countries.AQ' }),
    code: 'AQ',
    flag: AQFlag,
  },
  {
    name: intlFormatter.formatMessage({ id: 'Countries.BE' }),
    code: 'BE',
    flag: BEFlag,
  },
  {
    name: intlFormatter.formatMessage({ id: 'Countries.BR' }),
    code: 'BR',
    flag: BRFlag,
  },
  {
    name: intlFormatter.formatMessage({ id: 'Countries.BO' }),
    code: 'BO',
    flag: BOFlag,
  },
  {
    name: intlFormatter.formatMessage({ id: 'Countries.CL' }),
    code: 'CL',
  },
  {
    name: intlFormatter.formatMessage({ id: 'Countries.CO' }),
    code: 'CO',
    flag: COFlag,
  },
  {
    name: intlFormatter.formatMessage({ id: 'Countries.DE' }),
    code: 'DE',
    flag: DEFlag,
  },
  {
    name: intlFormatter.formatMessage({ id: 'Countries.DO' }),
    code: 'DO',
    flag: DOFlag,
  },
  {
    name: intlFormatter.formatMessage({ id: 'Countries.EC' }),
    code: 'EC',
    flag: ECFlag,
  },
  {
    name: intlFormatter.formatMessage({ id: 'Countries.ES' }),
    code: 'ES',
    flag: ESFlag,
  },
  {
    name: intlFormatter.formatMessage({ id: 'Countries.GB' }),
    code: 'GB',
    flag: GBFlag,
  },
  {
    name: intlFormatter.formatMessage({ id: 'Countries.HN' }),
    code: 'HN',
    flag: HNFlag,
  },
  {
    name: intlFormatter.formatMessage({ id: 'Countries.ID' }),
    code: 'ID',
    flag: IDFlag,
  },
  {
    name: intlFormatter.formatMessage({ id: 'Countries.MX' }),
    code: 'MX',
    flag: MXFlag,
  },
  {
    name: intlFormatter.formatMessage({ id: 'Countries.NL' }),
    code: 'NL',
    flag: NLFlag,
  },
  {
    name: intlFormatter.formatMessage({ id: 'Countries.PA' }),
    code: 'PA',
    flag: PAFlag,
  },
  {
    name: intlFormatter.formatMessage({ id: 'Countries.PH' }),
    code: 'PH',
    flag: PHFlag,
  },
  {
    name: intlFormatter.formatMessage({ id: 'Countries.PE' }),
    code: 'PE',
    flag: PEFlag,
  },
  {
    name: intlFormatter.formatMessage({ id: 'Countries.PY' }),
    code: 'PY',
    flag: PYFlag,
  },
  {
    name: intlFormatter.formatMessage({ id: 'Countries.SV' }),
    code: 'SV',
    flag: SVFlag,
  },
  {
    name: intlFormatter.formatMessage({ id: 'Countries.UY' }),
    code: 'UY',
    flag: UYFlag,
  },
  {
    name: intlFormatter.formatMessage({ id: 'Countries.ZA' }),
    code: 'ZA',
    flag: ZAFlag,
  },
] as ICountry[];
