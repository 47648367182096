const permissionScopes: { [key: string]: string } = {
  FULL_ACCESS: 'LOATSKULimit.App.FullAccess',
  IMPORT_LIMITS_PER_DDC: 'LOATInventoryVisibility.LimitsPerDDC.Write',
  IMPORT_LIMITS_PER_CLIENT: 'LOATInventoryVisibility.LimitsPerPOC.Write',
  MANAGE_LIMITS_PER_DDC: 'LOATInventoryVisibility.ManageLimits.Write',
  READ_LIMITS_PER_DDC: 'LOATInventoryVisibility.ManageLimits.Read',
  WRITE_DDC_EXCEPTION: 'LOATInventoryVisibility.DDCException.Write',
  READ_ATP_PAGE: 'LOATInventoryVisibility.InventorySummary.Read',
  READ_ATP_PARAMETERS_PAGE: 'LOATInventoryVisibility.ATPParameters.Read',
  WRITE_ATP_PARAMTERS_PAGE: 'LOATInventoryVisibility.ManageATPParameters.Write',
  READ_RISK_AVAILABILITY: 'LOATInventoryVisibility.RiskAvailability.read',
  WRITE_RISK_AVAILABILITY: 'LOATInventoryVisibility.RiskAvailability.write',
  INVENTORY_REPORT_DOWNLOAD: 'LOATInventoryVisibility.InventoryReport.Download',
  ORDERS_REPORT_DOWNLOAD: 'LOATInventoryVisibility.OrdersReport.Download',
  CONSULT_POC_LIMITS_READ: 'LOATInventoryVisibility.ConsultPOCLimits.Read'
};

export default permissionScopes;
