import { useAuthenticationService, useUserMetadata } from 'admin-portal-shared-services';
import { HOME_ROUTE, NOT_FOUND_ROUTE } from 'config/routes';
import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { setGroups, setListVendors, setUserData } from 'stores/User/UserEvents';
import { LastURLLocalStorage } from 'tracking/events/constants';
import { GetFirstPermitedRoute } from 'utils/GetFirstPermitedRoute/GetFirstPermitedRoute';
import { CheckOnePermission } from 'utils/userHasPermission/userHasPermission';
import { useRouteScopes } from '../../hooks/useRouteScopes/useRouteScopes';

interface Props {
  children: React.ReactNode;
}

function PrivateRoute({ children }: Props): JSX.Element {

  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const JWTDecoded = useAuthenticationService().parseJwt();

  let PATHNAME = NOT_FOUND_ROUTE;

  const scopes = useRouteScopes();
  const hasPermission = CheckOnePermission(scopes);
  const location = useLocation();

  const userData = useUserMetadata().data;
  const groups = useUserMetadata().data?.authorization?.groups;
  const vendors = useUserMetadata().data?.vendors;
  const isLoadingMetadata = useUserMetadata().isLoading;

  if (location.pathname === HOME_ROUTE && !hasPermission) {
    PATHNAME = GetFirstPermitedRoute();
  }

  const loadUserMetadata = React.useCallback(() => {
    setGroups(groups);
    setListVendors(vendors);
    setUserData({
      email: JWTDecoded?.email,
      country: JWTDecoded?.country,
      id: JWTDecoded?.aud
    });

    setIsLoading(false);

  }, [groups, vendors, userData])

  React.useEffect(() => {
    if (!isLoadingMetadata) {
      loadUserMetadata();
    }
  }, [isLoadingMetadata]);

  React.useEffect(() => {
    localStorage.removeItem(LastURLLocalStorage);
  }, []);

  return <>
    {isLoading ? <></> :
      (!hasPermission ? <Redirect to={{ pathname: PATHNAME }} /> : children)}
  </>;
}

export default PrivateRoute;
