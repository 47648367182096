import MessageMap from '../i18n.d';

const en: MessageMap = {
  breadCrumbs: {
    home: 'Limits upload',
    inventory: 'Limits upload',
    summary: 'Limited SKU management',
    clientManagement: 'POCs exception management',
    summaryInventory: 'Inventory overview',
    criticalInventory: 'Critical stock management',
    criticalLevel: 'Automated inventory control',
    rulesManagement: 'Rules management',
    rulesCreation: 'Rule creation',
    rulesEdit: 'Rule Editing',
    atpParameters: 'Stock reservation parameters',
    parametersCreation: 'Add new parameter',
    parametersEdit: 'Edit parameter',
    fileMonitoring: 'File Monitoring',
    help: 'Help',
  },
  sideMenu: {
    home: 'Limits upload',
    summary: 'Limited SKU management',
    clientManagement: 'POCs exception management',
    limitPerClient: 'Consult active limits',
    summaryInventory: 'Inventory summary',
    criticalInventory: 'Critical Inventory',
    atpParameters: 'Stock reservation parameters',
    fileMonitoring: 'File Monitoring'
  },
  home: {
    title: 'Zero stock SKUs',
    modelTitle: 'Download Model',
    modelText: "If you still don't have the default file, click the download button below.",
    importTitle: 'Upload Spreadsheet',
    importText: 'Import the list of SKUs that will be limited in BEES',
    modelButtonSKU: 'DC File',
    modelButtonClient: 'POC File',
    DDCOption: 'DC',
    clientOption: 'POC',
    initialDate: 'Start date',
    endDate: 'End date',
    dialogTitle: 'Do you confirm the upload of the file?',
    clientDialogText: 'Limit type: {value}',
    clientDialogDuration: 'Duration: <b>strong period</b>',
    clientDialogPeriod: 'Period: <b>strong period</b>',
    clientTabDateWarning: 'Limits will be processed out of business hours',
    managementTittle: 'Management',
    managementDialog: 'To view and manage limited SKUs, click the button below.',
    managementButton: 'Manage Limits',
  },
  summary: {
    headerTitle: 'Limit SKU management',
    title: 'SKU list by DC',
    titleFilters: 'Filter data',
    downloadTitle: 'List of Previous Reports',
    importLink: 'File upload',
    selectAllDDC: 'Select all DC',
    selectAllClients: 'Select all DC',
    selectFieldGeo: 'Select a region',
    selectFieldDDC: 'Select a DC',
    ddcCheckboxCount: '{count} of {total} DDCs selected',
    ddcCount: '{value} total DC',
    ddcSelectedCount: '{value} DCs selected',
    clientCount: '{value} total DC',
    clientSelectedCount: '{value} DCs  selected',
    skuCount: '{value} total SKU',
    skuSelectedCount: '{value} SKUs limited by selected',
    downloadRequestSuccess:
      'Your download was requested successfully! Use the downloads tab to download the file',
    search: 'Search Options',
    levelRegular: 'Regular',
    levelCritical: 'Critical',
    filter: {
      placeHolder: 'Search by code or description of the SKU',
      optional: '(Optional)',
    },
  },
  clientManagement: {
    title: 'POC management by exception',
    subTitle:
      'Import a list of POCs that have an exception in the DC record. This list will overwrite the DC that is imported by BEES.',
    importButton: 'Import POC list',
    modelFileButton: 'Model file',
    modalTitle:
      'Select the CSV file with the POC list and DC´s which will be treated as an exception',
    importSuccess: 'Exception DCs imported successfully!',
    importError: 'An error ocurred when importing the CSV file!',
  },
  stockBalance: {
    title: 'Current Inventory Summary',
    subTitle: 'Summary Overview',
    filterTitle: 'Custom report',
    filterSubTitle: 'Create a report with BEES Customer inventory data.',
    skuFilterTooltip: 'Search for SKU by typing or pasting in the field below',
    zeroValueLabel: 'Hide out-of-stock SKUs in DC stock',
    emptyTable: 'The customized inventory report will appear here.',
    distributionCenterTags: 'Selected distribution centers:',
    bannerAlertText: 'Exporting files. Check the File Monitoring page for status updates and download the files when they are ready.',
    bannerAlertButton: 'Go to file monitoring',
    subTitleExpandTable: 'ATP Details',
    infiniteText: `There's no information for the selected date as the SKU has infinite stock.`,
    unvailableText: `There's no information for the selected date as the SKU has unavailable stock.`,
    hasAllUnavailableOrInfinite: `There's no information for the selected date as the SKU has unavailable / infinite stock.`,
    table: {
      sku: 'SKU',
      stock: 'DC Stock',
      reserved: 'Reserved',
      beesStock: 'BEES Stock',
      outOfStock: 'Out of stock on',
      restocked: 'Restocked on',
      time: 'Out of stock (Total time)',
      limit: 'Limit DC',
      beesStockRegularStatus: 'Regular status',
      beesStockCriticalStatus: 'Critical status',
      lastUpdated: 'Last updated on',
      pending: 'Pending',
      consumed: 'Consumed'
    },
    importModal: {
      title: 'Export files',
      text: 'Choose the files you want to export and check the progress on the file monitoring page.',
      inventoryTitle: 'Inventory custom report',
      inventoryText: 'Export selected region, distribution center, SKU code, and inventory information.',
      orderTitle: 'Reserved order list',
      orderText: 'Export a list with all pending and confirmed orders.',
      disableOrderImport: 'There are no orders to be exported. Only orders with pending reservation are exported.'
    },
    stockPerDayChart: {
      subTitleDCStock: 'DC future stock',
      reserved: 'Reserved',
      stock: 'BEES Stock',
      infinite: 'Infinite Stock',
      unavailable: 'Unavailable',
      tooltip: {
        dcStock: 'DC Stock',
        infiniteText: 'Infinite',
      }
    }
  },
  ATPParameters: {
    title: 'Stock reservation parameters',
    buttons: {
      add: 'Add parameter',
    },
    filter: {
      allStatus: 'All types',
      activationStatus: 'Activation',
      deactivationStatus: 'Deactivation',
      searchPlaceholder: 'Search by DDC ID',
      search: 'Search'
    },
    table: {
      ddc: 'DC ID',
      skuCount: 'Total SKUs',
      type: 'Parameter type',
      created: 'Created on',
      edited: 'Edited on',
    },
    form: {
      addParameter: 'Add Parameter',
      editParameter: 'Edit Parameter',
      deleteParameter: 'Delete Parameter',
      addTitle: 'Add a new parameter',
      editTitle: 'Edit parameter',
      leaveTitle: 'Leave without saving changes?',
      leaveText: 'If you leave without saving changes or completing all the steps, you will lose all the editions.',
      deleteTitle: 'Delete parameter?',
      deleteText: 'You cannot undo this action.',

      typeAlert: 'Changing parameter types remove any add DDCs and SKUs from the fields below',
      successCreation: 'Parameter successfully added.',
      successEdition: 'Parameter successfully edited.',
      successDelete: 'Parameter successfully deleted.',
      steps: {
        stepParameters: 'Parameter type',
        stepDCs: 'DCs',
        stepSKUs: 'SKUs',
        stepFreeGoods: 'Combos and free goods',
        stepReservationWindow: 'Reservation window',
        stepReservationStop: 'Status to stop reservation',
      },
      StepParameterType: {
        title: 'Parameter type',
        subTitle: 'Select the stock reservation parameter type.',
        activation: 'Activation',
        activationText: 'Use it to activate stock reservation for DCs and SKUs.',
        deactivation: 'Deactivation',
        deactivationText: 'Use it to deactivate stock reservation for DCs and SKUs.'
      },
      StepDCs: {
        title: 'DCs',
        subTitle: 'Select if the parameter will activate stock reservation for all DCs or only specific ones.',
        allDDC: 'All DDCs',
        especificDDC: 'Specific DDCs',
        alert: 'Suggestion: select All DCs to ensure an automatic stock reservation for new DCs.',
        alertWithoutAllDDC: 'Suggestion: You already have an active parameter for All DCs, and now it is possible to register parameters for specific DCs only.',
        label: 'Select DCs',
        autocompleteDDC: 'Use the field below to type the DCs code and press ENTER to add.',
        placeholderDDC: 'Enter DDC ID, and add one at a time',
      },
      StepSKUs: {
        title: 'SKUs',
        subTitle: 'Select if the parameter will activate stock reservation for all SKUs or only specific ones.',
        allSKU: 'All SKUs',
        especificSKU: 'Specific SKUs',
        alert: 'Suggestion: select All SKUs to ensure an automatic stock reservation.',
        label: 'Select SKUs',
        autocompleteSKU: 'Use the field below to type the SKUs code and press ENTER to add.',
        placeholderSKU: 'Enter SKU, and add one at a time',
      },
      StepFreeGoods: {
        title: 'Combos and free goods',
        subTitle: 'Select if you want to reserve stock for SKUs included in combos and free goods.',
        labelCombos: 'Reserve stock for combos?',
        labelFreeGoods: 'Reserve free goods?'
      },
      StepReservationWindow: {
        title: 'Reservation window',
        subTitle: 'Set how many calendar days in advance the parameter will reserve stock for orders.',
        alert: 'Suggestion: set a reservation window of 14 days for the selected DCs, and only change it if the seller requests it.',
        label: 'Set reservation window',
        single: '1 day',
        plural: '{value} days',
        dayLabel: 'for the selected DDCs',
        addNew: 'Add another reservation window',
        placeholderSKU: 'Enter SKU, and add one at a time',
        infoModal: {
          title: 'Learn about the reservation window',
          text: 'It sets how many calendar days the system will reserve stock for orders in advance.',
          titleList: 'Example:',
          listItemOne: 'Current day:',
          listItemOneText: 'Aug 20.',
          listItemTwo: 'Selected reservation window:',
          listItemTwoText: '10 days',
          listItemThree: 'Stock reservation:',
          listItemThreeText: 'available for orders with delivery dates up to Aug 30.',
          bottomText: 'In this context, the stock is deducted once an order is placed between Aug 20 - Aug 30.'
        }
      },
      StepReservationStop: {
        title: 'Reservation stop',
        subTitle: 'Select the order status in BEES for which you want to stop the stock reservation.',
        alert: `Suggestion: the stock reservation stop should coincide with the moment the seller's ERP begins reserving stock.`,
        text: 'Select the order status at which the stock reservation must stop. Previous statuses will continue to reserve stock.'
      },
      labels: {
        paramterType: 'Parameter Type',
        ddc: `Select DDCs`,
        sku: `Select SKUs`,
        autocompleteDDC: 'Use the field below to type the DCs code and press ENTER to add.',
        autocompleteSKU: 'Use the field below to type the SKUs code and press ENTER to add.',
        placeholderDDC: 'Enter the DDC code',
        placeholderSKU: 'Enter the SKU code',
        reservationWindow: 'Reservation Window',
        reservationWindowTooltip:
          'How many days before the delivery date we must consider to reserve the orders',
        reservationWindowText: 'At least one delivery window for the entire DDC is required.',
        statusReservation: 'Status to stop reservation',
        statusReservationTooltip:
          'Which BEES order status ATP must consider to stop the reservation for each order',
      },
      reservationWindowOptions: {
        single: '1 day',
        plural: '{value} days',
        dayLabel: 'for the entire DDC',
        addNew: 'Add another window',
      },
      errors: {
        ddc: 'The specific DDCs must be entered',
        sku: 'The specific SKUs must be entered',
        reservationWindow: 'The specific SKUs must be entered in the reservation window',
        reservationStatus: 'At least one reservation status must be selected',
        blockedDDC: 'Already exists that DDC in another parameter - {value}',
      },
      deleteParameterDialog: {
        title: 'Delete this parameter?',
        text: 'This action is permanent and cannot be undone.',
      },
    },
    statusConsume: {
      PLACED: 'Placed',
      CONFIRMED: 'Confirmed',
      MODIFIED: 'Modified',
      INVOICED: 'Invoiced',
      AVAILABLE: 'Available',
      IN_TRANSIT: 'In-transit',
      PARTIAL_DELIVERY: 'Partial delivered',
      DELIVERED: 'Delivered',
    },
    status: {
      activation: 'Activation',
      deactivation: 'Deactivation',
    },
    errors: {
      sameSKU: 'You cannot insert the same sku for a diferent day option',
    },
  },
  selector: {
    empty: 'Select a DC',
  },
  buttons: {
    import: 'Import stock',
    blockSKU: 'Block SKU',
    unblockSKU: 'Unblock SKU',
    unblock: 'Unblock',
    confirm: 'Confirm',
    cancel: 'Cancel',
    importText: 'Click to search in your computer',
    continue: 'Proceed',
    search: 'Search',
    download: 'Download',
    apply: 'Apply',
    back: 'Back',
    review: 'Review',
    exit: 'Exit',
    create: 'Create',
    removeAll: 'Remove all',
    deactivate: 'Deactivate',
    edit: 'Edit',
    applyChanges: 'Apply changes',
    deleteYes: 'Yes, delete',
    goBack: 'Go back',
    showInventory: 'Show Inventory',
    refresh: 'Refresh',
    gotIt: 'Got it',
    export: 'Export'
  },
  importModal: {
    cancelButton: 'Cancel',
    submitButton: 'Import',
    title:
      'Select the .CSV file with the inventory list, when click on import the SKUs from the file will be blocked for sale on BEES',
    fileInput: 'Click here to select file',
    formatErrorMessage: 'Invalid file type, select a {value} file type',
    requestSuccess: 'Limits uploaded successfully!',
    requestFailed: 'An error ocurred when importing the CSV file!',
    requestFailedEmptyList: `The file doesn't have out-of-stock SKUs to block`,
  },
  SKUUnblockModal: {
    title: 'Do you confirm the unblocking of the SKUs shown below for BEES sales?',
    confirm: 'Yes',
    cancel: 'No',
    success: 'Unblock performed successfully',
    failure: 'An error ocurred when unblocking the SKU!',
  },
  SKUblockSummaryModal: {
    title: 'Do you confirm the upload of the file?',
    subTitle: 'See below the affected DCs and number of SKUs:',
    type: 'Limit type: ',
    cd: 'DC',
    countCd: '{cd} DC',
    countSKU: '{sku} SKUs',
    tableDC: 'Distribution Center',
    tableSKU: "Number of SKU's",
    failure: 'An error ocurred when blocking the SKU list!',
    startDate: 'Start',
    endDate: 'End',
    validity: 'Validity: ',
  },
  BoxImportData: {
    title: 'BEES last update date',
  },
  requestDeliveryCenter: {
    errorList: 'There was an error listing the DCs.',
  },
  Pagination: {
    selectedOne: '{value} selected item',
    selectedMany: '{value} selected itens',
    of: 'of',
  },
  DataGridBlockedSKU: {
    celSku: 'SKU',
    celLimit: 'DDC limit',
    celAvailable: 'Available',
    celDateRange: 'Date Range',
    celLimitSource: 'Limit source',
    celStartDate: 'Initial Validity',
    celEndDate: 'Final Validity',
    celAction: 'Action',
    celPOC: 'POC',
    celStatus: 'Status',
    celDuration: 'Limit duration',
    emptyRows: 'Select the DCs on the filter above',
    emptyRowsClients: 'Select the POCs on the filter above',
    noRows: 'No SKU found',
    errorRequest: 'An error occurred while listing the blocked SKU list',
    errorAccount: 'Invalid Account',

    searchField: 'Search by SKU code or description',
    buttonUnblock: 'Unblock',
    noPermission: 'No Permission',
    status: {
      pending: 'Pending',
      success: 'Sent',
      live: 'Live',
    },
  },
  Loading: {
    defaultLoadingBox: 'The data is being processed, please wait',
  },
  datePicker: {
    format: 'MM/dd/yyyy',
    formatTime: 'MM/dd/yyyy HH:mm',
    confirm: 'OK',
    cancel: 'Cancel',
    incorrectDate: 'Incorrect Date Format',
    minDate: 'Cannot be less than current date',
    minDateEnd: 'Cannot be less than the start date',
    defaultDateTimeLabelInitial: 'Start date and time',
    defaultDateTimeLabelEnd: 'End date and time',
  },
  importClientLimit: {
    success: 'Limits per POC successfully imported!',
    failure: 'An error ocurred when importing the file!',
  },
  uploadValidation: {
    fileSize: 'File size limit exceeded. The file size limit is {value}MB',
  },
  forms: {
    selectAll: 'Select all',
    unselectAll: 'Unselect all',
    searchField: 'Search by SKU code',
    emptyAtLeastOne: 'Must have at least one item',
    errorChips: 'Remove the following error chips to continue.',
    errorSameChip: 'You cannot add an item with the same name',
    searchFieldSKU: 'SKU code',
    searchFieldSKUPlaceholder: 'Enter SKU code',
  },
  general: {
    for: 'for',
    or: 'or',
    DDC: 'DC',
    pluralDDC: 'DCs',
    client: 'POC',
    pluralClient: 'POCs',
    downloads: 'Previous Reports',
    filters: 'Filters',
    region: 'Region',
    undo: 'Undo',
    optional: 'Optional',
    emptySearch: 'No records found for your search',
    show: 'Show Options',
    hide: 'Hide Options',
    diselectAll: 'Diselect all',
    applied: 'applied',
    autocompletecountLabel: '{value} item selected',
    clearAll: 'Clear all',
    yes: 'Yes',
    no: 'No',
    learnMore: 'Learn more',
    next: 'Next',
    continue: 'Continue',
    yesLeave: 'Yes, leave',
    noCancel: 'No, cancel',
    noGoBack: 'No, go back',
    yesDelete: 'Yes, delete',
    saveChanges: 'Save changes',
    date: 'Date',
    today: 'Today',
    period: 'Period',
    especificDate: 'Especific date',
    startDate: 'Start date',
    endDate: 'End date',
    exportBy: 'Export by'
  },
  clientTab: {
    title: 'SKU limited by POC',
    emptyState: 'Use the fields above to search for POCs and SKUs',
  },
  ddc: {
    title: 'SKU limited by DC',
    emptyState: 'Use the fields above to search for DCs and SKUs',
  },
  uploadList: {
    title: 'Upload',
  },
  uploadHistory: {
    title: 'Upload History',
  },
  limitPerClient: {
    filter: {
      title: 'Consult limits per POC',
      input: 'Search by POC Id',
      placeHolder: 'Enter account ID',
      toolTip:
        'The data is directly retrieved from BEES for consultation. Delays in integrating limits may lead to discrepancies between the information displayed here and on the limit management screen.',
    },
    table: {
      empty: 'Search by POC Id',
      sku: 'SKU',
      limit: 'Limit',
      balance: 'Balance',
      validStart: 'Initial Validity',
      validEnd: 'End Validity',
      loading: 'Loading Items',
      pageSizeOptions: 'Items per page: {value}',
      pageNumberIndicator: 'of',
      searchPlaceHolder: 'Search by SKU',
      status: 'Status',
      limitType: 'Type'
    },
    notfound: 'There’s no information to show',
    error: {
      size: 'Invalid Size',
      cpforcnpj: 'Invalid CPF or CNPJ',
      length: 'Field must have more than 1 digit',
    },
    imageText: 'See limited SKUs per POC',
  },
  exportSKUList: {
    table: {
      requestDate: 'Request Date',
      limitType: 'Limit Type',
      User: 'User',
      Status: 'Status',
      Actions: 'Actions',
      Info: 'Info',
    },
    filterTag: {
      limitType: '[Type] {value}',
      user: '[User] {value}',
      status: '[Status] {value}',
      dc: '[DC] {value}',
      name: '[Name] {value}',
      SKU: '[SKU] {value}',
    },
    status: {
      finished: 'Ready for download',
      inProgress: 'In progress',
      failed: 'Failed',
    },
  },
  customRequestErrors: {
    importSKUFile: `You don't have permission to set limits for DC {value}`,
    defaultRequestErrors: 'An unexpected error has occurred',
    defaultRequestErrorsATP: 'Oops, something went wrong, please try again later',
  },
  localErrors: {
    timezoneImport: 'Error importing file. You must select a time zone in your user profile.',
    timezoneDefault: 'You must select a time zone in your user profile.',
    emptyCountryList:
      "You don't have any vendor associated to your user, please contact the support team",
  },
  ModalSelectCountry: {
    title: 'Welcome!',
    text: 'Before start using LOAT, select the country you want to view.',
    label: 'Country:',
    emptyTitle: 'Registration error',
    emptyText:
      'It looks like your user registration is not complete. Contact the responsible for your user and try again later.',
  },
  Countries: {
    AR: 'Argentina',
    AQ: 'Antarctica',
    BE: 'Belgium',
    BR: 'Brazil',
    BO: 'Bolivia',
    CL: 'Chile',
    CO: 'Colombia',
    DE: 'Germany',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    ES: 'Spain',
    GB: 'United Kingdom',
    HN: 'Honduras',
    ID: 'Indonesia',
    MX: 'Mexico',
    NL: 'Netherlands',
    PA: 'Panama',
    PE: 'Peru',
    PH: 'Philippines',
    PY: 'Paraguay',
    SV: 'El Salvador',
    UY: 'Uruguay',
    ZA: 'South Africa',
  },
  ToolTipForTimeZone: {
    title: 'No time zone selected',
  },
  ImportFileDataTable: {
    fileName: 'File Name',
    uploadDate: 'Upload date',
    uploadBy: 'Uploaded by',
    uploadStatus: 'Upload Status',
    limitType: 'Limit Type',
    lines: 'Lines',
    successes: 'Successes',
    fails: 'Fails',
    toolTip: {
      titleWithErrors: 'Click to view all errors',
      titleWithoutErrors: 'There are no errors to Download',
    },
  },
  FiltersTitle: {
    region: 'Region and DC',
    limitType: 'Limit Type',
    user: 'User',
    status: 'Status',
    SKU: 'SKU',
    RuleName: 'Rule Name',
  },
  Placeholders: {
    user: 'Enter a e-mail',
    limitType: 'Select a limit type',
    status: 'Select a status',
    searchSKU: 'Search SKU',
    RuleName: 'Search Rule Name',
  },
  ExportLimitTypes: {
    finished: 'Ready for download',
    in_progress: 'In progress',
    failed: 'Failed',
  },

  DownloadErrorFiles: {
    title: 'Save as CSV file',
  },
  DialogWithTableError: {
    title: 'List of errors',
    noRows: 'No file has found',
    loading: 'Please wait while the data is loaded',
    overRows: 'Download the file to view all errors',
    column: {
      line: 'Line',
      message: 'Error description',
    },
  },
  UploadStatus: {
    inProgress: 'In progress',
    uploadSuccessfully: 'Uploaded',
    error: 'Fail',
    uploadWithErrors: 'Uploaded with errors',
  },
  criticalInventory: {
    title: 'Critical inventory management',
    subTitle: 'Set critical inventory levels and create rules for inventory limits.',
    text: 'Follow the steps below:',
    stepOneTitle: 'Step 1: Critical inventory level',
    stepOneText: 'Assign critical values ​​to inventory per DDC and SKU.',
    stepTwoTitle: 'Step 2: Rules management',
    stepTwoText: 'Create and manage rules for inventory limits per DDC and POC.',
    form: {
      stepOneText: 'Create',
      stepOneEditText: 'Edit',
      stepTwoText: 'Review',
      dialogBackTitle: 'You want to exit?',
      dialogBackText:
        'The contents of your rule will not be saved, you’ll have to start over if you want to create the rule.',
      successCreation: 'Your rule has been created! ',
      successEdition: 'The rule has been updated! ',
      pocRequestError: 'All POCs entered are invalid. Insert existing POCs',
      StepOne: {
        title: 'Name your rule and set the rule type:',
        RuleNameLabel: 'Rule name',
        RuleNamePlaceholder: 'Name should be 50 characters long or less',
        RuleTypeLabel: 'Rule type',
        RuleDescription: 'What is this rule about?',
        RuleDescriptionDDC:
          'This type of rule will automatically activate or deactivate an SKU for all the POCs in the DDC.',
        RuleDescriptionPOC:
          'This type of rule will limit the POCs you choose to have a specific quantity of an SKU available for purchase.',
        turnOn: 'Turn-on rule',
        turnOff: 'Turn-off rule',
        dialogStatusTitle: 'Do you want to disable this rule?',
        dialogStatusText:
          'This action will be immediate and the rule will remain inactive until it is activated again.',
      },
      StepTwo: {
        title: 'Set a DDC to place your rule:',
        RegionLabel: 'Region',
        RegionPlaceholder: 'Choose a region',
        DDCLabel: 'Distribution center',
        DDCPlaceholder: 'Select a distribution center',
      },
      StepThree: {
        title: 'Choose the product that will be limited',
        SKULabel: 'SKU',
        SKUPlaceholder: 'SKUs list',
        Qty: 'Limit qty',
        actions: 'Actions',
        skuHaveRuleError: 'The sku {value} already have a rule created.',
      },
      StepFour: {
        title: 'Choose the POCs',
        POCLabel: 'POCs',
        POCPlaceholder: 'POCs list',
        maxPOCWarningTitle: 'You uploaded more POCs than what is allowed!',
        maxPOCWarningText:
          'Creating a POC rule only allows you to upload up to 400 POC’s, you can choose to delete the last exceeding number of POCS or go back and copy and paste the exact amount.',
        maxPOCWarningAccept: 'Delete exceeding POCs',
        maxPOCToastError: `You cannot add new POCs, the limit of {value} has been reached`,
        informationTextChip: 'You can also copy and paste upt to 400 POCs from your CSV',
      },
      Review: {
        title: 'Review your information',
      },
    },
    Filter: {
      clearStates: 'Clear All',
    },
  },
  rulesManagementList: {
    title: 'Rules management',
    searchPlaceholder: 'Search by rule type, status, DDC, POC or SKU',
    createRule: 'Create rule',
    table: {
      emptyTitle: 'Create a rule',
      emptyText: 'There are no created rules, add one and visualize the information here!',
      name: 'Rule name',
      type: 'Rule type',
      ddc: 'DC',
      sku: 'SKU',
      qty: 'Limit qty',
      poc: `POC’s`,
      actions: 'Actions',
      lastEvent: 'Last event',
      status: 'Status',
    },
  },
  criticalLevel: {
    title: 'Automated Inventory Control',
    searchPlaceholder: 'Search by DDC or SKU',
    createCriticalLevel: 'Add new critical level',
    table: {
      emptyTitle: 'Create a critical stock rule',
      emptyText: 'There are no created rules, add one and visualize the information here!',
      ddc: 'DDC',
      sku: 'SKU id',
      criticalLevel: 'Critical stock',
      created: 'Date created',
      updated: 'Last update',
    },
    modal: {
      title: 'Add new critical level',
      titleEdit: 'Edit critical level',
      regionPlaceholder: 'Select a region',
      skuPlaceholder: 'Search SKU',
      skuText: 'Search for SKU by typing or pasting in the field below',
      criticalLevelInput: 'Add critical level value',
      criticalLevelPlaceholder: 'Input number',
      successInsert: 'A new critical level has been successfully added.',
      successUpdate: 'Changes applied to critical level.',
      successDelete: 'Critical level deleted.',
      deleteDialogTitle: 'Delete critical level',
      deleteDialogText: 'This action is permanent and cannot be undone.',
    },
  },
  fileMonitoring: {
    ATPExport: {
      table: {
        type: 'Type',
        fileName: 'File name',
        exportedOn: 'Exported on',
        exportedBy: 'Exported by',
        status: 'Status',
        action: 'Action'
      },
      typeText: {
        inventory: 'Inventory custom report',
        order: 'ATP reserved order list'
      },
      dialogError: {
        title: 'Unable to process the file',
        text: 'We are facing issues to process the file. Try again later.'
      }
    }
  },
  notFoundPage: {
    titleAccessDenied: 'Hmm... Access denied!',
    textAccessDenied: `It looks like you don't have permission to see this page.`
  },
  defaultStatusTypes: {
    active: 'Active',
    inactive: 'Inactive',
    applied: 'Applied',
    processing: 'Processing',
    error: 'Error',
    readyDownload: 'Ready for download'
  },
};

export default en;
