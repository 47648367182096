import { useAnalyticsService, useAuthenticationService } from 'admin-portal-shared-services';
import { useEffect } from 'react';

export const useSegmentAnalytics = (segmentKey: string): void => {
  const analyticsService = useAnalyticsService();
  const jwtDecoded = useAuthenticationService().parseJwt();

  useEffect(() => {
    analyticsService.load(segmentKey);
    analyticsService.identify({ first_name: jwtDecoded?.name, email: jwtDecoded?.email });
  }, [analyticsService, segmentKey]);

};

export default useSegmentAnalytics;
