import { usePermissionService } from 'admin-portal-shared-services';
import permissionRoutes from 'config/permissionRoutes';
import { NOT_FOUND_ROUTE } from 'config/routes';

export function GetFirstPermitedRoute(): string {

    let route: string = NOT_FOUND_ROUTE;

    for (let currentRoute in permissionRoutes) {
        const currentRouteScopes = permissionRoutes[currentRoute];

        const hasAccessRoute = currentRouteScopes.reduce((access, currentScope) => {
            if (usePermissionService().has(currentScope)) {
                return true;
            } else {
                return access;
            }
        }, false);

        if (hasAccessRoute) {
            route = currentRoute;
            break;
        }
    }

    return route;
}
