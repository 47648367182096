import { createEvent } from 'effector';

export const setOpenCountryModal = createEvent<boolean>('setOpenCountryModal');

export const setSelectedCountry = createEvent<string>('setSelectedCountry');

export const setSelectedVendor = createEvent<string>('setSelectedVendor');

export const setForceDownloadTab = createEvent<boolean>('setForceDownloadTab');

export const setSendToDownloadTab = createEvent<boolean>('setSendDownloadTab');

export const setInventoryDisableDownloadButton = createEvent<boolean>('setInventoryDisableDownloadButton');

export const setListVendors = createEvent<any[] | undefined>('setListVendors')

export const setGroups = createEvent<string[] | undefined>('setGroups')

export const setUserData = createEvent<{
    email: string | undefined,
    country: string | undefined,
    id: string | undefined
}>('setUserData');

export const setLastUrl = createEvent<string>('setLastUrl')
