import { createStore } from 'effector';
import _ from 'lodash';
import { setLoading } from './LoadingEvents';
import LoadingState from './LoadingState';

const initialState: LoadingState = {
  show: false,
  message: undefined,
};

const CreateLoadingStore = createStore(initialState).on(setLoading, (state, payload) => {
  let show;
  let message;

  if (payload instanceof Object) {
    show = payload.show;
    message = payload.message;
  } else {
    show = payload;
  }

  return _.cloneDeep({
    ...state,
    show,
    message,
  });
});

export default CreateLoadingStore;
