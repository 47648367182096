import { useUserMetadata } from 'admin-portal-shared-services';
import { LOCAL_STORAGE_LANGUAGE_KEY } from 'config/constants';
import React, { ReactNode } from 'react';
import { IntlProvider as NativeProvider } from 'react-intl';
import MessageMap from './i18n.d';
import en from './languages/en';
import es from './languages/es';
import pt from './languages/pt';
import { mergeMessages } from './utils';

const DEFAULT_LANGUAGE = 'en-US';
type IIntlProvider = {
  children: ReactNode;
};
const messages: { [language: string]: MessageMap } = {
  'en-US': en,
  'pt-BR': pt,
  'es-419': es,
};

const IntlProvider = ({ children }: IIntlProvider): JSX.Element => {
  const { data } = useUserMetadata();

  const locale = data?.profile?.preferredLanguage ?? DEFAULT_LANGUAGE;

  const mergedMessages = mergeMessages(messages, locale, DEFAULT_LANGUAGE);

  React.useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_LANGUAGE_KEY, locale);
  }, [data?.profile?.preferredLanguage]);

  return (
    <NativeProvider locale={locale} defaultLocale={DEFAULT_LANGUAGE} messages={mergedMessages}>
      {children}
    </NativeProvider>
  );
};

export default IntlProvider;
