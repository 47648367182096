import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      flex: 1,
    },
    containerBody: {
      display: 'flex',
    },
  })
);

export default useStyles;
