import { useSidebar } from 'admin-portal-shared-services';
import sideBarItems from './sideBarItems';

const useSideBar = (): any => {

  useSidebar({
    items: sideBarItems(),
    utils: [],
  });
};

export default useSideBar;
