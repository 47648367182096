import MessageMap from './i18n.d';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any */
const flattenObject = (ob: any): any => {
  const toReturn: { [key: string]: any } = {};

  for (const i in ob) {
    if (typeof ob[i] === 'object' && ob[i] !== null) {
      const flatObject = flattenObject(ob[i]);
      // eslint-disable-next-line guard-for-in
      for (const x in flatObject) {
        toReturn[`${i}.${x}`] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
};
/* eslint-enable */

const mergeMessages = (
  messagesInput: MessageMap,
  selectedLocale: string,
  defaultLangue: string
): any => {
  const defaultMessages = flattenObject(messagesInput[defaultLangue]);
  const localeMessages = flattenObject(messagesInput[selectedLocale]);

  return { ...defaultMessages, ...localeMessages };
};

export { mergeMessages, flattenObject };
