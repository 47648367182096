import DesignTokensProvider from '@admin-portal-shared-components/design-tokens-provider';

import { createGenerateClassName, CssBaseline, StylesProvider } from '@material-ui/core';
import {
  createFeatureToggleServiceV2,
  generateIncrementalCounter,
} from 'admin-portal-shared-services';
import useSegmentAnalytics from 'hooks/useSegmentAnalytics/useSegmentAnalytics';
import React from 'react';
import Router from 'Router';
import { OPTIMIZELY_PROJECT_NAME } from './config/constants';
import IntlProvider from './i18n/provider';
import { EnvProps, EnvProvider } from './utils/envProps/envProps';

const styleKey = 'admin-portal-availability-mfe';
const counterGenerator = generateIncrementalCounter(styleKey);

function App(envProps: EnvProps): JSX.Element {
  const generateClassName = createGenerateClassName({
    productionPrefix: styleKey,
    seed: styleKey,
    customGetNextCounterId: counterGenerator,
  } as any);

  useSegmentAnalytics(envProps.segmentKey);

  createFeatureToggleServiceV2(OPTIMIZELY_PROJECT_NAME, envProps.optimizelyKey);

  return (
    <IntlProvider>
      <EnvProvider env={envProps}>
        <StylesProvider generateClassName={generateClassName}>
          <CssBaseline />
          <DesignTokensProvider>
            <Router />
          </DesignTokensProvider>
        </StylesProvider>
      </EnvProvider>
    </IntlProvider>
  );
}

export default App;
