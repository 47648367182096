import { createStore } from 'effector';
import _ from 'lodash';
import { setForceDownloadTab, setGroups, setInventoryDisableDownloadButton, setLastUrl, setListVendors, setOpenCountryModal, setSelectedCountry, setSelectedVendor, setSendToDownloadTab, setUserData } from './UserEvents';
import UserState from './UserState';

const initialState: UserState = {
  openCountryModal: false,
  selectedCountry: '',
  selectedVendor: '',
  forceDownloadTab: false,
  inventoryDisableDownloadButton: false,
  sendToDownloadTab: false,
  listVendors: [],
  groups: [],
  data: {
    email: '',
    country: '',
    id: ''
  },
  lastUrl: ''
};

const CreateUserStore = createStore(initialState)
  .on(setOpenCountryModal, (state, openCountryModal) =>
    _.cloneDeep({
      ...state,
      openCountryModal,
    })
  )
  .on(setSelectedCountry, (state, selectedCountry) =>
    _.cloneDeep({
      ...state,
      selectedCountry,
    })
  )
  .on(setSelectedVendor, (state, selectedVendor) =>
    _.cloneDeep({
      ...state,
      selectedVendor,
    })
  )
  .on(setForceDownloadTab, (state, forceDownloadTab) =>
    _.cloneDeep({
      ...state,
      forceDownloadTab
    })
  ).on(setInventoryDisableDownloadButton, (state, disableDownloadButton) =>
    _.cloneDeep({
      ...state,
      inventoryDisableDownloadButton: disableDownloadButton
    })
  ).on(setSendToDownloadTab, (state, sendToDownloadTab) =>
    _.cloneDeep({
      ...state,
      sendToDownloadTab
    })
  ).on(setListVendors, (state, listVendors) =>
    _.cloneDeep({
      ...state,
      listVendors
    })
  ).on(setGroups, (state, groups) =>
    _.cloneDeep({
      ...state,
      groups
    })
  ).on(setUserData, (state, data) =>
    _.cloneDeep({
      ...state,
      data
    })
  ).on(setLastUrl, (state, lastUrl) =>
    _.cloneDeep({
      ...state,
      lastUrl
    })
  );


export default CreateUserStore;
