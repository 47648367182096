import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    loading: {
      position: 'fixed',
      width: '100%',
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      backgroundColor: '#3731316b',
      overflow: 'hidden',
      zIndex: 9999,
    },
    circular: {
      color: '#000000',
      position: 'absolute',
      top: '50%',
      left: '50%',
    },
    messageBox: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: '40%',
      margin: 'auto',
      backgroundColor: '#004A74F7',
      maxWidth: '300px',
      padding: '20px',
      color: '#FFF',
      textAlign: 'center',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
      '& div': {
        color: '#FFF',
        float: 'left',
      },
    },
    messageText: {
      paddingLeft: '20px',
    },
  })
);

export default useStyles;
