import React from 'react';

export type EnvProps = {
  optimizelyKey: string;
  segmentKey: string;
  vendorKey: string;
  vendorKeyAR: string;
  vendorKeyCO: string;
  vendorKeyDO: string;
  vendorKeyEC: string;
  vendorKeyHN: string;
  vendorKeyMX: string;
  vendorKeyPA: string;
  vendorKeyPE: string;
  vendorKeyPY: string;
  vendorKeySV: string;
  vendorKeyUY: string;
  vendorKeyZA: string;
};

const defaultInitialValue: EnvProps = {
  optimizelyKey: '',
  segmentKey: '',
  vendorKey: '',
  vendorKeyAR: '',
  vendorKeyCO: '',
  vendorKeyDO: '',
  vendorKeyEC: '',
  vendorKeyHN: '',
  vendorKeyMX: '',
  vendorKeyPA: '',
  vendorKeyPE: '',
  vendorKeyPY: '',
  vendorKeySV: '',
  vendorKeyUY: '',
  vendorKeyZA: '',
};

export const EnvContext = React.createContext(defaultInitialValue);

export const useEnvProps = (): EnvProps => React.useContext(EnvContext);

export const EnvProvider = ({
  env,
  children,
}: {
  env: any;
  children: React.ReactNode;
}): JSX.Element => <EnvContext.Provider value={env}>{children}</EnvContext.Provider>;
